<template>
    <b-container>
        <b-table
            show-empty
            small
            stacked="md"
            :items="records"
            :fields="table.fields"
            :current-page="table.currentPage"
            :per-page="table.perPage"
            :filter="table.filter"
            :filterIncludedFields="table.filterOn"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
            :sort-direction="table.sortDirection"
            @filtered="onFiltered"
        >
            <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="info(row.item, row.index, $event.target)">編輯</b-button>
                <b-button size="sm" @click="delete_items(row.item)">刪除</b-button>
            </template>
        </b-table>

        <b-modal :id="infoModal.id" :title="infoModal.title">
            <b-form class="px-2 py-3" >
                <b-row>
                    <b-col cols="12">
                        <h3 class="font-weight-bold text-center">基本資料</h3>
                    </b-col>
                    <b-col cols="12">
                        <b-row class="border border-white m-1 px-1 py-2">
                            <b-col cols="6" md="4" lg="3">
                                姓名*: {{ form.name }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                身分證字號*: {{ form.id_number }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                行動電話*: {{ form.mobile }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                年齡: {{ form.age }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                生日: {{ form.birthday }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                公司/住家電話: {{ form.tel }}
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" class="mt-3">
                        <h3 class="font-weight-bold text-center">工作資料</h3>
                    </b-col>
                    <b-col cols="12">
                        <b-row class="border border-white m-1 px-1 py-2">
                            <b-col cols="6" md="4" lg="3">
                                工作類型*: {{ form.job }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                收入方式: {{ form.income_type }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                月薪(元): {{ form.income_amount }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                投保單位: {{ form.insured }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                薪轉: {{ form.income_proof }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                工作年資(年): {{ form.working_years }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                扣憑單: {{ form.withholding }}
                            </b-col>

                            <b-col cols="6" md="4" lg="3">
                                扣憑單金額(元): {{ form.withholding_amount }}
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" class="mt-3">
                        <h3 class="font-weight-bold text-center">負債情況</h3>
                    </b-col>
                    <b-col cols="12">
                        <!-- 信用卡 -->
                        <b-row class="border border-white m-1 px-1 py-2">
                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                信用卡
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        擁有信用卡: {{ show.have_credit_card}}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_credit_card">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                信用卡銀行: {{ form.credit_bank }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信用卡額度(萬): {{ form.credit_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信用卡使用時間(年): {{ form.credit_use_years }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                卡別: {{ form.credit_type }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                是否循環: {{ form.have_revolving }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                循環額度(萬): {{ form.revolving_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                是否繳款正常: {{ form.credit_pay_for_delay }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                現金卡
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        擁有信用卡: {{ show.have_cash_card }}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_cash_card">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                現金卡額度(萬): {{ form.cash_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                是否繳款正常: {{ form.cash_pay_for_delay }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                信用貸款
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        是否有個人信貸: {{ show.have_loan }}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_loan">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                信貸銀行: {{ form.loan_bank }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信貸申請額度(萬): {{ form.loan_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信貸剩餘額度(萬): {{ form.loan_remain }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信貸申請期數: {{ form.loan_total_month }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                信貸月付金: {{ form.load_each_month_paid }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                房屋貸款
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        是否有房貸 {{ show.have_home_loan }}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_home_loan">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                房貸銀行 {{ form.home_loan_bank }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                房貸申請額度(萬) {{ form.home_loan_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                房貸已繳期數 {{ form.home_loan_paid_month }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                房貸月付金額 {{ form.home_loan_each_month_paid }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                企業貸款
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        是否有企業貸款: {{ show.have_enterprise_loan }}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_enterprise_loan">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                企業貸款申請銀行: {{ form.enterprise_loan_bank }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                企業貸款申請額度(萬): {{ form.enterprise_loan_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                企業貸款剩餘額度(萬): {{ form.enterprise_loan_remain }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                企業貸款申請期數: {{ form.enterprise_loan_total_month }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                企業貸款已繳期數: {{ form.enterprise_loan_each_month_paid }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="1" class="text-center font-weight-bold font-md">
                                汽車貸款
                            </b-col>
                            <b-col cols="12" lg="11" class="">
                                <b-row>
                                    <b-col cols="12">
                                        是否有車貸 {{show.have_car}}
                                    </b-col>

                                    <b-col cols="12" v-if="show.have_car">
                                        <b-row>
                                            <b-col cols="12" lg="4">
                                                車牌號碼: {{ form.license_plate_number }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸申請銀行: {{ form.car_loan_bank }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸申請額度(萬): {{ form.car_loan_amount }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸剩餘額度(萬): {{ form.car_loan_remain }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸申請期數: {{ form.car_loan_total_month }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸剩餘期數: {{ form.car_loan_remain_month }}
                                            </b-col>
                                            <b-col cols="12" lg="4">
                                                車貸月付金: {{ form.car_loan_each_month_paid }}
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="12" lg="12">
                                其他貸款: {{form.other_load}}
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" class="mt-3">
                        <h3 class="font-weight-bold text-center">服務單位</h3>
                    </b-col>
                    <b-col cols="12">
                        <b-row class="border border-white m-1 px-1 py-2">
                            <b-col cols="12" lg="4">
                                公司名稱: {{ form.company }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                公司類型: {{ form.company_type }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                公司統一編號: {{ form.company_id }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                公司電話: {{ form.company_tel }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                公司分機: {{ form.company_tel_ext }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                公司地址: {{ form.company_address }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                職稱: {{ form.title }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                年資: {{ form.experience }}
                            </b-col>

                        </b-row>
                    </b-col>

                    <b-col cols="12" class="mt-3">
                        <h3 class="font-weight-bold text-center">申請資料</h3>
                    </b-col>
                    <b-col cols="12">
                        <b-row class="border border-white m-1 px-1 py-2">
                            <b-col cols="12" lg="4">
                                買賣金額(萬): {{ form.trade_amount }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                欲申貸金額(萬): {{ form.apply_amount }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                欲申貸期數: {{ form.apply_total_amount }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                希望月付金: {{ form.apply_each_month_paid }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                學歷: {{ form.education }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                電子信箱: {{ form.email }}
                            </b-col>
                            <b-col cols="12" lg="4">
                                通訊地址: {{ form.address_of_tel }}
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'formData',
    data() {
        return {
            table: {
                fields: [
                    {key: 'id', label: '編號', sortable: true, sortDirection: 'desc'},
                    {key: 'question', label: '問題'},
                    {key: 'answer', label: '答案'},
                    {key: 'create_datetime', label: '建立時間'},
                    {key: 'update_uid', label: '更新人'},
                    {key: 'actions', label: '編輯'}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                pageOptions: [5, 10, 15],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
            },
            records: [],
            infoModal: {
                id: 'formDataDetail',
                title: '',
                content: '',
                question: '',
                answer: '',

            },
            form: {
                name: '',
                id_number: '',
                mobile: '',
                age: '',
                birthday: '',
                tel: '',
                job: '',
                income_type: '',
                income_amount: '',
                insured: '',
                income_proof: '',
                working_years: '',
                withholding: '',
                withholding_amount: '',
                have_credit_card: '',
                credit_bank: '',
                credit_amount: '',
                credit_use_years: '',
                credit_type: '',
                have_revolving: '',
                revolving_amount: '',
                credit_pay_for_delay: '',
                cash_card: '',
                cash_amount: '',
                cash_pay_for_delay: '',
                have_loan: '',
                loan_bank: '',
                loan_amount: '',
                loan_remain: '',
                loan_total_month: '',
                load_each_month_paid: '',
                have_home_loan: '',
                home_loan_bank: '',
                home_loan_amount: '',
                home_loan_paid_month: '',
                home_loan_each_month_paid: '',
                have_enterprise_loan: '',
                enterprise_loan_bank: '',
                enterprise_loan_amount: '',
                enterprise_loan_remain: '',
                enterprise_loan_total_month: '',
                enterprise_loan_each_month_paid: '',
                have_car: '',
                license_plate_number: '',
                car_loan_bank: '',
                car_loan_amount: '',
                car_loan_remain: '',
                car_loan_total_month: '',
                car_loan_remain_month: '',
                car_loan_each_month_paid: '',
                other_load: '',
                company: '',
                company_type: '',
                company_id: '',
                company_tel: '',
                company_tel_ext: '',
                company_address: '',
                title: '',
                experience: '',
                trade_amount: '',
                apply_amount: '',
                apply_total_amount: '',
                apply_each_month_paid: '',
                education: '',
                email: '',
                address_of_tel: '',
            },
            show: {
                have_credit_card: false, // 信用卡
                have_cash_card: false, // 現金卡
                have_loan: false, // 個人信貸
                have_home_loan: false, // 房貸
                have_enterprise_loan: false, // 企業貸款
                have_car: false, // 車貸
            },
            options: {
                have: [
                    {value: true, 'text': '有'},
                    {value: false, 'text': '沒有'},
                ],
                job: [
                    {value: '上班族', 'text': '上班族'},
                    {value: '公務員', 'text': '公務員'},
                    {value: '職業軍人', 'text': '職業軍人'},
                    {value: '自營商', 'text': '自營商'},
                    {value: '自由業', 'text': '自由業'},
                    {value: '家管', 'text': '家管'},
                    {value: '其他', 'text': '其他'},
                ],
                income_type: [
                    {value: '領現金', 'text': '領現金'},
                    {value: '銀行轉帳', 'text': '銀行轉帳'},
                ],
                insured_unit: [
                    {value: '公司', 'text': '公司'},
                    {value: '公會', 'text': '公會'},
                    {value: '無勞健保', 'text': '無勞健保'},
                ],
                company_type: [
                    {value: '自營', 'text': '自營'},
                    {value: '受雇', 'text': '受雇'},
                ],
                education: [
                    {value: '研究所', 'text': '研究所'},
                    {value: '大專院校', 'text': '大專院校'},
                    {value: '高中職', 'text': '高中職'},
                    {value: '國中', 'text': '國中'},
                    {value: '國小', 'text': '國小'},
                    {value: '其他', 'text': '其他'},
                ],
            },
        }
    },

    async created() {
        let response = await this.axios.post(
            this.GLOBAL.serverSrc + "loan_form.php",
            {
                username: sessionStorage.getItem('account'),
                token: sessionStorage.getItem('token'),
                action: "list"
            }
        );

        if (response.data.status === '1') {
            this.records = response.data.data;
        }

        console.log(this.records);
    },

    methods: {
        switchBoolean(option) {
            let ans = option;
            for (let i = 0; i< this.options.have.length; i++) {
                let item = this.options[i];
                if (item.value === option) {
                    ans = item.text;
                    break;
                }
            }
            return ans;
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.infoModal.id = item.id
            this.infoModal.answer = item.answer
            this.infoModal.question = item.question
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
    }
}
</script>